<div class="grid"    >
	<div class="col-12"    >
		<div class="card fadein animation-duration-500  border-round m-2 px-5 py-3 min-w-full"   >

			<!--------------------Consultation Tickets PAGE---------------------->

            <div
             style="display: flex; justify-content: space-between; align-items: center; padding-bottom : 10px;">
                <h4>Consultation Vente</h4>
                <p-button (click)="showOverlayPanel($event)">
                    <ng-template pTemplate="icon">
                      <i class="fas fa-cog fa-2x"  style="color: white;"></i>
                    </ng-template>
                  </p-button>
              </div>

       <!-------------------- OVERLAYPANEL (selection des columns) ---------------------->

              <p-overlayPanel #overlayPanel [dismissable]="true" [showCloseIcon]="true">
                <p-multiSelect
                  [options]="Cols"
                  [(ngModel)]="SelectedCols"
                  optionLabel="header"
                  [filter]="true"
                  [style]="{ 'max-width': '300px', 'min-width': '250px' }"
                ></p-multiSelect>
              </p-overlayPanel>
            <h6> La date de dernière modification : {{lastdate | date:'short'}} </h6>


       <!--------------------Tableau Client ---------------------->

            
            <p-table
            [paginator]="true"
            class="fadeinleft animation-duration-1000   border-round m-2 px-5 py-3"
            [value]="Ventes"
            [columns]="SelectedCols"
            [tableStyle]="{'min-width': '60rem'}"
            [rows]="10"
            sortMode="single"
            responsiveLayout="stack"
            scrollHeight="flex"
            [totalRecords]="totalRecords"
            [showCurrentPageReport]="true"
            dataKey="id"
            [paginator]="false"
            [lazy]="true"
            [loading]="loading"
            (onLazyLoad)="onLazyLoad($event)"
            >

            <ng-template
            class="fadeinleft animation-duration-1000 font-bold bg-primary border-round m-2 px-5 py-3" 
            pTemplate="caption" style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;">
              
                <div style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;">
                  <p-multiSelect
                  [options]="Societes"
                  placeholder="Societes"
                  optionLabel="name"
                  [filter]="true"
                  [style]="{ 'margin-right': '5px', 'margin-bottom': '5px', 'max-width': '250px', 'min-width': '150px' }"
                  (onChange)="onSocieteSelect($event)"
                  [(ngModel)]="selectedD"
                ></p-multiSelect>
                
                <p-multiSelect
                [options]="Depots"
                placeholder="Depot"
                optionLabel="name"
                [filter]="true"
                [style]="{ 'margin-right': '5px', 'margin-bottom': '5px', 'max-width': '250px', 'min-width': '150px' }"
                (onChange)="onStoreSelect($event)"
                [(ngModel)]="selectedS"
              ></p-multiSelect>

                <!-- <p-multiSelect
                [options]="Clients"
                placeholder="Fournisseur"
                optionLabel="name"
                [filter]="true"
                [style]="{ 'margin-right': '5px' , 'margin-bottom': '5px',  'max-width' : '250px' ,'min-width' : '150px'}">
                </p-multiSelect>
                <p-multiSelect
                [options]="articles"
                placeholder="Article"
                optionLabel="code_article_generique"
                [filter]="true"
                [style]="{ 'margin-right': '5px' , 'margin-bottom': '5px' , 'max-width' : '250px','min-width' : '150px'}">
                </p-multiSelect> -->


                <!-- <p-inputGroup [style]="{ 'margin-right': '5px' , 'margin-bottom': '5px' , 'max-width' : '250px','min-width' : '150px'}" >
                <p-inputGroupAddon>De </p-inputGroupAddon>
                <p-inputNumber [(ngModel)]="value" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"> </p-inputNumber>
                <p-inputGroupAddon>ttc</p-inputGroupAddon>
                </p-inputGroup>


                <p-inputGroup [style]="{ 'margin-right': '5px' , 'margin-bottom': '5px' , 'max-width' : '250px','min-width' : '150px'}">
                <p-inputGroupAddon>A </p-inputGroupAddon>
                <p-inputNumber [(ngModel)]="value2" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"> </p-inputNumber>
                <p-inputGroupAddon> ttc</p-inputGroupAddon>
                </p-inputGroup> -->
<p-calendar
                    [(ngModel)]="date"
                
                    selectionMode="range"
                    placeholder="Date"
                    [readonlyInput]="true"
                    style="margin-right: 5px ; margin-bottom: 5px;">
                    </p-calendar>
          
               <p-button   
                      (click) = "filtrer()"
                       icon="fa fa-filter bi bi-filter" 
                       label="Filtrer" 
                       class="p-button-success mr-0.5 mb-"
                       >
               </p-button>
               <p-button
                        (click) = "reset()"
                           icon="bi bi-arrow-clockwise" 
                           label="Défiltrer" 
                           class="btn btn-primary mr-0.5 mb-" 
               >
               </p-button>  
            </div> 

           </ng-template>

          <!------------------------------------------------------ Columns -------------------------------------------------------->


               <ng-template pTemplate="header" let-columns >
                    
                    
                    
                <tr>
                  <th *ngIf="!editmodeopen()" style="width: 2% ; align-items: center;">
                    <div *ngIf="!editmodeopen()" class="field-checkbox">
                      <p-checkbox [binary]="true" [(ngModel)]="selectAllCheckbox"
                      (onChange)="onSelectAllCheckboxChange()"></p-checkbox>
                    </div>
                  </th>
                  <th *ngFor="let col of columns" pSortableColumn="{{ col.field }}">
                    {{ col.header }}
                    <p-sortIcon field="{{ col.field }}"></p-sortIcon>
                  </th>
                  <th >Stock</th>
                <th >Stock Cegid</th>
                  <th style="width: 15%">
                    <span class="p-input-icon-left mb-2">
                      <i class="pi pi-search"></i>
                      <input
                        pInputText
                        type="text"
                        [(ngModel)]="filter"
                        (input)="searchVente()"
                        placeholder="Rechercher"
                        class="w-full"
                        style="height: 35px;"
                      />
                      <span class="p-input-icon-right mb-2" (click)="clearFilter()">
                        <i class="pi pi-times" ></i>
                      </span>
                    </span> 
                  </th>
                </tr>
              </ng-template>
          <!---------------------------------------------------- Table body ------------------------------------------------------------>

              <ng-template pTemplate="body" let-vente let-rowIndex="rowIndex">
                <tr [ngClass]="{'bg-bluegray-200': shouldDisablePen(vente)}" >

                  <!-------------------- check items   ---------------------->

                  <td *ngIf="!editmodeopen()">
                    <div  class="field-checkbox">
                      <p-checkbox [binary]="true" [(ngModel)]="vente.selected" (ngModelChange)="change()"></p-checkbox>
                    </div>
                  </td>
                  <td *ngFor="let col of SelectedCols">
             

                    <!-------------------- Display mode  ---------------------->

                    <ng-container *ngIf="!vente.editMode; else cellEditor">
                      {{ vente[col.field] }}
                    </ng-container>

                    <!-------------------- Edit mode  ---------------------->

                    <ng-template #cellEditor>
                      <ng-container *ngIf="col.field === 'souche'; else numberInput">
                        <!-- Render p-multiselect for souche -->
                        <p-autoComplete
                        [(ngModel)]="vente[col.field]"
                        [dropdown]="true"
                        [suggestions]="DepotsEdit"
                        (completeMethod)="filterStore($event)"
                        (ngModelChange)="onAutoCompleteChange($event)"
                        ></p-autoComplete>

                      </ng-container>
                    
                      <ng-template #numberInput>
                        <!-- <p-inputNumber [disabled]="shouldDisablePen(vente)" [(ngModel)]="vente[col.field]" name="{{ col.field }}" *ngIf="col.field === 'qty' "></p-inputNumber>
                        <p-inputNumber [disabled]="shouldDisablePen(vente)" [(ngModel)]="vente[col.field]" name="{{ col.field }}" *ngIf="col.field ===  'prixttc'"></p-inputNumber> -->
                        <p-inputNumber [disabled]="shouldDisablePen(vente)" [(ngModel)]="vente[col.field]" name="{{ col.field }}" *ngIf="col.field === 'totalttc' "></p-inputNumber>
                        <!--<span >{{ vente[col.field] }}</span>-->
                      <!--*ngIf="col.field !== 'qty' && col.field !== 'prixttc' && col.field !== 'totalttc'" -->
                      </ng-template>
                    </ng-template>
                    
   
                  </td>
                   <td  *ngIf="vente.stock.length  ">
                       
                     <div *ngFor="let  stock of vente.stock">
    • {{ stock.societe }} : {{stock.stock}}
  </div>
                  </td>
                     <td  *ngIf="!vente.stock.length ">
                    <span style="color : red ;" >Non disponible</span>
                  </td>
                   
                    <td  >
                     {{vente.stockCegid}}
                  </td>
                  <td  class="flex flex-wrap align-items-center justify-content-center" >
                     

                    <!-------------------- Action icon  ---------------------->

                    <ng-container *ngIf="!vente.editMode; else editActions">
                      <p-button *ngIf="!editmodeopen()" class="pr-2"  icon="pi pi-pencil"  (click)="handlePenClick(vente , rowIndex)"></p-button>
                      <p-button *ngIf="!editmodeopen()" icon="bi bi-send"  severity="secondary" class="pr-2"  (click)="senddata(vente ,rowIndex )"></p-button>
                      <p-button *ngIf="!editmodeopen()" icon="pi pi-times"  severity="danger" (click)="deletedata(vente ,rowIndex )"></p-button>
                    </ng-container>

                  <!-------------------- Action icon apres modification ---------------------->

                    <ng-template #editActions>
                      <p-button  *ngIf="!shouldDisablePen(vente)" icon="pi pi-check" class="pr-4" (click)="saveChanges(vente)" ></p-button>
                      <p-button  *ngIf="!shouldDisablePen(vente)" icon="pi pi-times" (click)="cancelEditing(vente)"></p-button>
                    </ng-template>
                  </td>
                
                </tr>
                
              
         
              </ng-template>
              
              
          
            

               <!-------------------- footer Paginator and gererate button  ---------------------->
              
              <ng-template pTemplate="summary">
                     <tr>
                     
                                 <h5> Le total des ventes sélectionnées est : {{ Totalselected }} TND</h5>
                  </tr>
                <div class="flex flex-row-reverse flex-wrap">
                  <!--<p-button-->
                  <!--  badge= '{{select()}}'-->
                  <!--  (click)="showdialog()"-->
                  <!--  icon="bi bi-send"-->
                  <!--  label="Générer"-->
                  <!--  size="large"-->
                  <!--  class="p-button-success mr-0.5 mb- pl-8 flex-shrink-1 flex align-items-center justify-content-center bigger-button"-->
                  <!--></p-button>-->
                  <p-paginator  #paginator
                    [totalRecords]="totalRecords"
                    [rows]="size"
                    [rowsPerPageOptions]="[10, 25, 50 , 100 , 200 , 500]"
                    [pageLinkSize]="5"
                    [alwaysShow]="false"
                    (onPageChange)="onPageChange($event)"
                    class="paginator-no-background"
                  ></p-paginator>
                </div>
              </ng-template>              
            </p-table> 

               
               
              

        </div>
        <!------------------------------------------------------------------------------------------------------------------------>
<app-distribution-dialog (dialogClose)="getVente()" ></app-distribution-dialog>
</div>
<p-toast></p-toast>
</div>
