import { Component, OnInit , ViewChild  } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import {GetFromStrapiService} from '../../../Services/GetStrapi/GetFromStrapi.service' ;
import {Vente} from '../../../Models/Ventes' ;
import {DistributionDialogComponent} from '../../../Pages/distribution-dialog/distribution-dialog.component';
import {MessageService} from 'primeng/api';
import { Societe, Store } from 'src/app/Facture/Models/Societe';
import {RANDOMAPIService} from '../../../Services/RandomAPI/RANDOMAPI.service' ;
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-consultation-vente',
  templateUrl: './consultation-vente.component.html',
  styleUrls: ['./consultation-vente.component.scss']
})
export class ConsultationVenteComponent implements OnInit {
  @ViewChild('overlayPanel') overlayPanel!: OverlayPanel;
  @ViewChild(DistributionDialogComponent) Dialog: DistributionDialogComponent;
  @ViewChild('paginator') paginator: any;
  

//--------------------- Declaration ----------------------------//
   filter  
   Ventes : Vente[] = []
   generatedList: Vente[] = []
   totalRecords: number;
   page: number = 1;
   size: number = 10;
   sortField: string | undefined;
   sortOrder: number | undefined;
   loading = false ;
   date
   editMode = false
   selectAllCheckbox: boolean = false;
   filteredstore: string[] = ["R2", "R9"];
   Societes : Societe[]
   selectedSociete:Societe []
   selecteddepot : Store[]
   Depots: Store[] = [];
   DepotsEdit : string [] = [] ;
   Totalselected : number  = 0 ;
      defaultDate: Date;
      selectedD = []
      selectedS = []
      lastdate 
      
      stockAvaible = false ;
//--------------------- NgOninit et constructor ------------------//

  constructor(private messageService: MessageService,private Strapi : GetFromStrapiService , private random : RANDOMAPIService , private primengConfig: PrimeNGConfig) { 
  }
   setDefaultTime() {
    console.log(this.date)
  }

      async ngOnInit() {
          this.lastdate = await this.random.getUrl("https://connecteur.devsty-it.com/api/datejob")
          this.lastdate = this.lastdate.attributes.job_vente
          console.log('la dateeeeeeeeeee estt aqfffaf',this.lastdate)
          this.Totalselected = 0 ;
          this.getVente();
          try {
        const data = await this.Strapi.getFromStrapi("societes" ,1,25,null,null,null,null,null,null,'populate=icon' );
        this.Societes = Societe.mapSocietes(data);
        this.Societes.forEach( async (Societe1) => {
          const url = Societe1.api_url
          const data =  await this.random.getUrl(`https://${url}stores?`)
          Societe1.stores = Store.mapSocietes(data)
          Societe1.stores.forEach((store)=> {
                console.log('storeeee ' , store)
                this.DepotsEdit.push(store.code)
            })
        });
      }catch(e) {
        throw e;
      }
      }
  onSocieteSelect(event: any) {
  const selectedSocietes: Societe[] = event.value;

  this.Depots = [];
  this.selecteddepot = [];

  // Iterate through selected Societes and add their stores to Depots
  selectedSocietes.forEach((selectedSociete) => {
    this.Depots = this.Depots.concat(selectedSociete.stores);
  })

  // Remove duplicates (if any)
  this.Depots = Array.from(new Set(this.Depots));
      }
      onStoreSelect(event){
   this.selecteddepot = event.value;
   console.log(this.selecteddepot)
      }
//--------------------------- Edit mode ----------------------//
      filterStore(event: any) {
          console.log("les depot sont ", this.DepotsEdit)
        const query = event.query;
        this.DepotsEdit = this.DepotsEdit
          .filter(store => store.toLowerCase().includes(query.toLowerCase()));
      }
      shouldDisablePen(vente: any): boolean {
        return vente.selected && !this.isFirstSelected(vente);
      }
      handlePenClick(vente: Vente,i : number): void {
        this.Ventes[i].editMode = ! this.Ventes[i].editMode
        this.Ventes.forEach((vent, i) => {
          if (vent.selected) {
            this.Ventes[i].editMode = true ;
          }
        });
      }
      async senddata(vente: Vente , i : number){
          console.log('meddd',vente)
      let ids = []
      this.Ventes[i].selected = true ;
      console.log('soucheeeee',vente.souche)
      console.log('sositeiseeeee',this.Societes)
  // Assuming "R9" is the code you're searching for
        const foundIndex = this.Societes.findIndex(societe => societe.stores.some(store => store.code === vente.souche));
        
        let foundIndexstore = -1;
        if (foundIndex !== -1) {
            foundIndexstore = this.Societes[foundIndex].stores.findIndex(store => store.code === vente.souche);
        }

        console.log("Societe index:", foundIndex);
        console.log("Store index:", foundIndexstore);
              let datacode = [];
              this.generatedList = []; 
              this.Ventes.forEach((vent) => {
                console.log(vent.selected);
                if (vent.selected) {
                  ids.push(vent.id);
                    const existingItem = this.generatedList.find((item) => item.code_article_generic === vent.code_article_generic && item.prixttc === vent.prixttc);  
                    if (existingItem) {
                      existingItem.qty += vent.qty || 0;
                    } else {
                      this.generatedList.push({ ...vent });
                    }
                } 
              });
      console.log(foundIndex)
      console.log(foundIndexstore)
      if (foundIndex != -1 && foundIndexstore != -1){
        const uniqueSouches = Array.from(new Set(this.generatedList.map(vente => vente.souche)));
        console.log("uniqueesoucheee" , uniqueSouches)
        if(uniqueSouches.length>1){
          this.messageService.add({severity:'error', summary:'Depot', detail:'Selectionner le meme depot ou modifier'});
          setTimeout(() => {
            this.messageService.clear();
          },4000);
        }else{
          this.generatedList.forEach((vent, i) => {
            const  data = {"code": vent.code_article_generic,
            "prix": vent.totalttc,
            "qty": vent.qty,
            "qtytotal": vent.qty}
            datacode.push(data)
           }) 
           
           const data = [{ "id": this.Societes[foundIndex].id,
           "ids" : ids,
           "name": this.Societes[foundIndex].name,
        //   "codes": datacode , 
           "store": this.Societes[foundIndex].stores[foundIndexstore].code
         }]
         this.loading = true;
         await this.Strapi.postStrapi('manageVente',data)
         this.getVente();
         this.loading = false;
        }
      }else{
        this.messageService.add({severity:'error', summary:'Depot', detail:'Depot non trouvable dans votre liste de groupes'});
          setTimeout(() => {
            this.messageService.clear();
          },4000);
      }
      }
      async deletedata(vente: Vente , i : number){
       const id = this.Ventes[i].id
       const data = {"data" :{
         "available": false
       }}
       this.loading=true;
      await this.Strapi.updateById('ventes',id,data)
      this.loading=false;
       this.getVente()
      }
      editmodeopen():boolean{
        return this.Ventes.some(vente => vente.editMode === true);
      }
      onAutoCompleteChange(event: any) {
        console.log('AutoComplete value changed:', event);
        
        this.Ventes.forEach((vent, index) => {
          console.log(vent.selected);
          if (vent.selected) {
            this.Ventes[index].souche = event;
          }
        });
      }
      saveChanges(vente: Vente ): void {
        console.log('vente', vente);
        this.Ventes.forEach((vent, index) => {
          const data = {"data" : {
              "nature": vent.nature,
              "qty": vent.qty,
              "prixttc": vent.prixttc,
              "totalttc": vent.totalttc,
              "numero": vent.numero,
              "souche": vent.souche,
              "code_article_generic": vent.code_article_generic,
              "code_a_barre": vent.code_a_barre,
              "gl_numero": vent.gl_numero,
              "gl_typeprovenance": vent.gl_typeprovenance,
          }
          };
          this.Strapi.updateById('ventes' , vent.id , data)
          console.log(vent.selected);
          if (vent.selected) {
          // this.Ventes[index] = { ...this.Ventes[index], ...vente };
          }
          this.Ventes[index].editMode = false ; 
        });
      }
      cancelEditing(vente: Vente): void {
        this.Ventes.forEach((vent, index) => {
          vent.editMode = false
        });
      }
      isFirstSelected(vente: any): boolean {
        const firstSelectedIndex = this.Ventes.findIndex(item => item.selected);
        return firstSelectedIndex === -1 || this.Ventes.indexOf(vente) === firstSelectedIndex;
      }
      getVenteFieldValue(index: number, fieldName: string): any {
        const vente = this.Ventes[index];
        return vente ? vente[fieldName] : null;
      }
  //--------------------------- CheckedBox ----------------------//
      onSelectAllCheckboxChange() {
            this.Ventes.forEach((vente) => (vente.selected = this.selectAllCheckbox));
              this.Totalselected = 0; // reset total selected before calculating
    this.Ventes.forEach((vente) => {
        if(vente.selected) {
            this.Totalselected += vente.totalttc
        }
    });
    this.Totalselected = parseFloat(this.Totalselected.toFixed(3))
      }
      
      isAnyClientSelected(): boolean {
            return !this.Ventes || this.Ventes.every(client => !client.selected);
      }
      
     change() : void{
    console.log("changinggg ...")
    this.Totalselected = 0; // reset total selected before calculating
    this.Ventes.forEach((vente) => {
        if(vente.selected) {
            this.Totalselected += vente.totalttc
        }
    });
    this.Totalselected = parseFloat(this.Totalselected.toFixed(3))
}
  //---------------------- CRUD Vente -------------------------- //
      reset(){
        this.selectedD = []
        this.selectedS = []
        this.selecteddepot = []
        this.paginator.changePage(0);
        this.date = undefined;
        this.getVente();
      }
      async getVente() {
          this.Totalselected=0;
        const datetochange = this.date
        if (datetochange ) {
            datetochange[0].setHours(1);
            datetochange[0].setDate(datetochange[0].getDate() - 1);
            datetochange[0].setDate(datetochange[0].getDate());
          
            // datetochange[1] ?   datetochange[1].setDate(datetochange[1].getDate() ): '';
        }
        let  add : string = ''
        if (this.selecteddepot){
          this.selecteddepot.forEach((selected) => {
            add += `filters[$and][0][souche][$eq]=${selected.code}&`;
          })
        }
        console.log('sort filed :',this.sortField)
        console.log('sort order : ',this.sortOrder)
        this.loading = true;
        try {
          const data = await this.Strapi.getFromStrapi('ventes', this.page, this.size , this.sortField , this.sortOrder , this.filter, 'code_article_generic', null , datetochange , add + '&filters[available][$eq]=true' , 'datecreation');
          this.Ventes = Vente.mapVentes(data);
          this.totalRecords = this.Strapi.total
           if (datetochange ){
          datetochange[1] ?   datetochange[1].setDate(datetochange[1].getDate() - 1): '';
           }
          if(this.selecteddepot.length === 1 ){
            this.stockAvaible = true ;
            const foundIndex = this.Societes.findIndex(societe => societe.stores.some(store => store.name === this.selecteddepot[0].name));
            let url = `https://${this.Societes[foundIndex].api_url}`
            console.log(url);
            const barcodelist = this.Ventes.map(v => v.code_article_generic);
            const barcodelistString = barcodelist.join(",");
            console.log(barcodelistString)
            url+= `getStockByEtablissement?`
            url += `fltrproduct=${barcodelistString}`
            url += `&fltrstore=${this.selecteddepot[0].id}`
            console.log(url)
            let dataAvaible
            try{
             dataAvaible =  await this.random.getUrl(url)
             this.Ventes.forEach(v => {
                dataAvaible.stocks.forEach( d => 
                v.code_article_generic === d.code_article_generique ? v.stock = d.total_quantity.toString() : '')
            }) 
            console.log('nouvel',this.Ventes)
            }catch(e){
                
            }
       console.log('data is ',dataAvaible)  

          
          }else{
              this.stockAvaible = false ;
          }
        } catch (error) {
          console.error('Error fetching ventes:', error);
        } finally {
          this.loading = false;
        }
        console.log(this.Ventes)
        console.log(this.totalRecords)
      }
      clearFilter() : void {
        this.filter = '';
        this.searchVente();
      }
      searchVente(): void {
        this.paginator.changePage(0);
        this.getVente()
      }
//-------------------- dynamic table columns  --------------------------//

      Cols: any[] = [
        { field: 'numero', header: 'Numéro' },
        { field: 'code_article_generic', header: 'Code Article Générique' },
        { field: 'nature', header: 'Nature' },
        { field: 'qty', header: 'Quantité' },
        { field: 'prixttc', header: 'Prix TTC' },
        { field: 'totalttc', header: 'Total TTC' },
        { field: 'souche', header: 'Souche' },
        { field: 'code_a_barre', header: 'Code-barres' },
        { field: 'gl_numero', header: 'Numéro GL' },
        { field: 'gl_typeprovenance', header: 'Type de Provenance GL' },
          { field: 'datecreation', header: 'Date Vente' },
           { field: 'fournisseur', header: 'Fournisseur' },
          { field: 'payment', header: 'Payment' },
      ];
      SelectedCols: any[] = [
        this.Cols[8],
        this.Cols[0],
        this.Cols[1],
        this.Cols[6],
        this.Cols[3],
        this.Cols[4],
        this.Cols[5],
        this.Cols[10],
        this.Cols[5],
        this.Cols[11],
        this.Cols[12],
      ];
      showOverlayPanel(event: Event) {
        this.overlayPanel.show(event);
      }
      //-------------------- paginator sort  --------------------------//
      onLazyLoad(event: LazyLoadEvent): void {
        this.sortField = event.sortField;
        this.sortOrder = event.sortOrder;
        this.paginator.changePage(0);
        this.getVente();
      }
      get totalPages(): number {
        return Math.ceil(this.totalRecords / this.size); 
      }
      onPageChange(event: any): void {
        this.page = event.page + 1;
        this.size = event.rows;
        this.getVente();
      }
      //-------------------- Dialog --------------------------//
      generateList(ventes: Vente[]): Vente[] {
        const result: Vente[] = [];
      
        ventes.forEach((vente) => {
          const existingItem = result.find((item) => item.code_article_generic === vente.code_article_generic);
      
          if (existingItem) {
            // If the item already exists, update its quantity
            existingItem.qty += vente.qty || 0;
          } else {
            // If the item doesn't exist, add it to the result list
            result.push({ ...vente });
          }
        });
        return result;
      }
      ids : any[] = [];
      showdialog() {
      this.generatedList = []; 
      this.Ventes.forEach((vent) => {
        console.log(vent.selected);
        if (vent.selected) {
          this.ids.push({"id" :vent.id, "code" :vent.code_article_generic});
          
            const existingItem = this.generatedList.find((item) => item.code_article_generic === vent.code_article_generic && item.prixttc === vent.prixttc);  
            if (existingItem) {
              existingItem.qty += vent.qty || 0;
            } else {
              this.generatedList.push({ ...vent });
            }
        } 
      });
      console.log(this.generatedList)
      if (this.generatedList.length > 0 ){
        const uniqueSouches = Array.from(new Set(this.generatedList.map(vente => vente.souche)));
        if(uniqueSouches.length>1){
          this.messageService.add({severity:'error', summary:'Depot', detail:'Selectionner le meme depot ou modifier'});
          setTimeout(() => {
            this.messageService.clear();
          },4000);
        }else{
          this.Dialog.showdialog(this.generatedList ,null ,this.ids)
        }
      }else{
        this.messageService.add({severity:'error', summary:'Vide', detail:'Selectionner un code article'});
      setTimeout(() => {
        this.messageService.clear();
      },4000);
       }
       this.ids = [];
      }
      //--------------------- calender --------------------------//
      filtrer() {
        this.paginator.changePage(0);
        this.getVente()
      }
      select() : number {
        const num = this.Ventes.filter(v => v.selected === true).length 
        return num
      }
}
      
