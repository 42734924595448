import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {MessageService} from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class GetFromStrapiService {

  private apiUrl = 'https://connecteur.devsty-it.com/API/';
  total = 0 ;
  statuscode ;
  constructor(private http: HttpClient , private messageService: MessageService) { }

    //-------------------------------- Get Strapi --------------------------------//

  async getFromStrapi( table : string , page ?: number, size ?: number , field?: string , option? : any , filter ?: string , searshdata1? : string , searshdata2? : string ,date?  ,add ? , datefield? ): Promise<any[]> {
    this.statuscode = 0
    this.apiUrl = 'https://connecteur.devsty-it.com/API/';
    this.apiUrl = `${this.apiUrl}${table}?`
    let searchUrl = `filters[$or][0][${searshdata1}][$containsi]=${filter}&`;
    searchUrl = searshdata2 ?  `${searchUrl}&filters[$or][1][${searshdata2}][$containsi]=${filter}`: searchUrl
    const sortOrder = option === 1 ? 'ASC' : 'DESC';
    let sort = field && option ? `sort=${field}:${sortOrder}&` :  ''
    if (table === 'ventes' &&  !field ) {
        sort = `sort=gl_numero:DESC&`
    }
    const pagin = `pagination[page]=${page}&pagination[pageSize]=${size}`;
    let GetApi = ''
    if(filter && filter != ''){
       GetApi = `${this.apiUrl}&${pagin}&${sort}&${searchUrl}`
    }else{
       GetApi = `${this.apiUrl}&${pagin}&${sort}`
    }
    add ? GetApi = `${GetApi}${add}` : '';
    date ?  GetApi = `${GetApi}${this.StrapiFilterDate(date, 0 , datefield)}` : '' ;
    try {
      const apiResponse: any = await this.http.get(GetApi).toPromise();
      console.log('API Response:', apiResponse);
    
      // Check for errors in the response
      if (apiResponse && apiResponse.data) {
        this.total = apiResponse.meta?.pagination?.total;
        return apiResponse.data;
      } else {
        // Handle error response
        console.error('Error in API response:', apiResponse);
    
        // Check for status code in case of an error
        if (apiResponse && apiResponse.status) {
          // Assuming the error message is in the response body
          const errorMessage = apiResponse.error?.message || 'Une erreur s\'est produite lors de la récupération des données.';
          
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de récupération',
            detail: errorMessage,
          });
        }
    
        throw new Error('Error in API response');
      }
    } catch (error) {
      // Handle error and display an error message
      console.error('Error fetching clients:', error);
    
      let errorMessage = 'Une erreur s\'est produite lors de la récupération des données.';
      
      if (error && error.error && error.error.message) {
        errorMessage = error.error.message;
      }
    
      this.messageService.add({
        severity: 'error',
        summary: 'Erreur de récupération',
        detail: errorMessage,
      });
    
      throw error;
    }
  }  

  // ----------------------------- Date configuration ------------------------------- //
  formatDateToYYYYMMDD(date : any) {

    date.setDate(date.getDate() + 1);
    return date.toISOString();
    
  } 
  StrapiFilterDate(date : any[] , count , datefield : string): string {
    let filter = '';
    if (Array.isArray(date)) {

      if (date.length === 2 && date[1] != null) {
        const startDate = this.formatDateToYYYYMMDD(date[0]);
        const endDate = this.formatDateToYYYYMMDD(date[1]);
        filter += `&filters[$and][${count}][${datefield}][$gte]=${startDate}`
        count += 1;
        filter += `&filters[$and][${count}][${datefield}][$lte]=${endDate}`;
      } else if (date.length === 2 && date[1] == null) {
        const formattedDate = this.formatDateToYYYYMMDD(date[0]);
        filter += `&filters[$and][${count}][${datefield}][$eq]=${formattedDate}`;
      } else {
        console.error('Invalid date range:', date);
      }
    } else {
      const formattedDate = this.formatDateToYYYYMMDD(date);
      filter += `&filters[$and][${count}][${datefield}][$eq]=${formattedDate}`;
    }
    console.log('filter is ' , filter);
    return filter
  }

  //-------------------------------- Post Strapi --------------------------------//
  async postStrapi(table: string, classe: any): Promise<any> {
    this.apiUrl = 'https://connecteur.devsty-it.com/api/';
    this.apiUrl = `${this.apiUrl}${table}`;
    try {
       await this.http.post<any>(this.apiUrl, classe) .toPromise().then(
        response=>{
          if(response.status === 200 || response.status === 201 || response)
          {console.log("resp",response)
          this.messageService.add({
            severity: 'success',
            summary: 'Validation réussie',
            detail: '',
          });
          return response;}
          else{
            
        throw response;
          }
        }
       
      ).catch(error=>{
        console.error('Error in API call:', error.error.non_existant_article);
        let errorMessage = 'Une erreur s\'est produite lors de la validation.';
        console.log('le erueer est ',error)
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        console.log('no existant ', error.non_existant_article)
            console.log('no  5  ', error.error.non_existant_article)
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de validation'  ,
          detail: error.error.error +"  :" +  error.error.non_existant_article ,
        });
        throw error;
      });
      // Check if the API response contains a success message
      
       
    } catch (error) {
      console.error('Error in API call:', error);
      
    }
  }
  
  //-------------------------------- Delete Strapi --------------------------------//
  deleteById(table , id: number): Promise<any> {
    this.apiUrl = `${this.apiUrl}${table}/`
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete(url).toPromise();
  }
   //-------------------------------- Update Strapi --------------------------------//
  async updateById(table ,id: number, updatedclasse): Promise<any> {
    this.apiUrl = 'https://connecteur.devsty-it.com/api/';
    this.apiUrl = `${this.apiUrl}${table}/`
    const url = `${this.apiUrl}${id}`;
    try {
      const response = await this.http.put<any>(url, updatedclasse).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }
}
